/*------------- #NAVIGATION --------------*/


.navigation {
  margin: 20px 0;
  width: 100%;
  position: relative;
}

.page-numbers {
  position: relative;
  background-color: #eaf4fe;
  display: inline-block;
  margin: 0 12px 0 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: $icon-color;
  font-size: 14px;
  border-radius: 100%;
  transition: all .3s ease;
  font-weight: $h-font-weight;

  &:hover {
    background-color: $primary-color;
    color: $white-color;
    box-shadow: 10px 0 50px rgba(0,131,255,.15);
  }

  &.current {
    background-color: transparent;
    box-shadow: 10px 0 50px rgba(0,131,255,.15);
    color: $primary-color;
  }

  span {
    position: relative;
  }
}


/*================= Responsive Mode ============*/


@media (max-width: 640px) {
  .page-numbers {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin: 0 8px 0 0;
  }
}

@media (max-width: 460px) {
  .page-numbers {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    margin: 0 8px 0 0;
  }
}