/*------------- #COUNTING-DOWN --------------*/



.counting-down {
  border-radius: 10px;
  box-shadow: 10px 0 50px 0 rgba(18,25,33, 0.1);
  overflow: hidden;

  & > * {
	padding: 40px;
  }

  .timer {
	font-size: 38px;
  }
}

.counting-header {
  background-color: #d2e9f7;
  position: relative;

  .utouch-icon {
	fill: #B5CCE0;
	width: 150px;
	height: 150px;
	position: absolute;
	top: 25px;
	right: -75px;
  }

  .clock {
	margin-top: 20px;
    .text{
      text-transform: uppercase;
    }

  }


}

.counting-date {
  background-color: $blue-lighteen;

  .utouch-icon {
	fill: $blue-light;
  }

  .text {
	color: $icon-color;
  }
}

.counting-footer {
  background-color: $white-color;

  .author-prof {
	color: $heading-color;
  }

  .author-name {
	color: $icon-color;
  }

  .author-block {
	margin-bottom: 40px;
  }
}



/*================= Responsive Mode ============*/


@media (max-width: 980px) {
  .counting-down .timer {
	font-size: 16px;
  }

  .counting-down > * {
	padding: 15px;
  }

  .counting-header .utouch-icon {
	max-height: 50%;
  }
}