/*------------- #CHOOSE --------------*/



.choose {
  font-size: 0;
  display: table;
  width: 100%;
  height: 340px;
}

.choose-item {
  width: 33.33%;
  color: $white-color;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  padding: 20px 20px 20px 120px;

  &:after {
	display: block;
	position: absolute;
	z-index: 10;
	top: 0;
	right: -40px;
	content: "";
	width: 0;
	height: 100%;
	border-style: solid;
	border-width: 170px 0 170px 40px;
	border-color: transparent;
  }

  .title {
	text-transform: uppercase;
	font-size: 26px;
	letter-spacing: .2em;
	margin-bottom: 30px;
	color: $white-color;
  }

  .text {
	opacity: .5;
	transition: all .3s ease;
  }

  .btn-next {
	margin-left: -25px;

	.utouch-icon {
	  fill: $white-color;
	}

	&:hover {
	  margin-left: 5px;
	}
  }

  &:hover {
	.text {
	  opacity: 1;
	}

	.btn-next {
	  opacity: 1;
	}
  }

  &.bg-primary-color {
	&:after {
	  border-left-color: $primary-color;
	}
  }

  &.bg-orange {
	&:after {
	  border-left-color: $orange;
	}
  }

  &.bg-red {
	&:after {
	  border-left-color: $red;
	}
  }

  &:last-child:after {
	display: none;
  }
}



/*================= Responsive Mode ============*/


@media (max-width: 1199px) {
  .choose-item {
	padding-left: 60px;
  }
}

@media (max-width: 860px) {
  .choose-item .title {
	letter-spacing: 0.02em;
  }

  .choose-item::after {
	display: none;
  }
}

@media (max-width: 768px) {

  .choose {
	display: block;
	height: auto;
  }

  .choose-item {
	width: 100%;
	display: block;
	padding: 20px 30px;
  }
}

@media (max-width: 600px) {
  .choose {
	  display: block;
	  height: auto;
	}

  .choose-item {
	display: block;
	width: 100%;
	padding: 20px 0 30px;

	&:after {
	  display: none;
	}
  }
}