/*------------- #HEADING --------------*/



.crumina-heading {
  position: relative;
  z-index: 5;
  margin-bottom: 30px;

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & + * {
    margin-top: 25px;
  }
}

.heading-title {
  margin-bottom: 30px;
  line-height: 1.3em;
}

.heading-text {
  margin-top: 20px;
  font-weight: 400;
  line-height: 1.75;

  &.small {
    font-size: 14px;
  }
}

.heading-sup-title {
  opacity: .7;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: $body-font-size;
}

/*================= Responsive Mode ============*/


@media (max-width: 980px) {
  .heading-text {
    font-size: 20px;
  }
}

@media (max-width: 570px) {
  .heading-text {
    font-size: 16px;
  }
}