/*------------- #TEAMMEMBERS --------------*/



.teammembers-wrap {
  .crumina-teammembers-item {
	margin-bottom: 80px;
  }
}

.crumina-teammembers-item {
  .teammembers-thumb {
	width: 260px;
	height: 260px;
	background-color: #edf6fc;
	border-radius: 100%;
	text-align: center;
	float: left;
	margin-right: 40px;
	position: relative;
	margin-bottom: 30px;

	img {
	  position: absolute;
	  bottom: -30px;
	  left: 50%;
	  transform: translate(-50%, 0)
	}
  }

  .teammember-content {
	overflow: hidden;
	padding-top: 15px;
  }

  .teammembers-item-prof {
	text-transform: uppercase;
	font-size: 14px;
	color: $icon-color;
	font-weight: $h-font-weight;
	margin-bottom: 17px;
  }

  .teammembers-item-name {
	margin-bottom: 20px;
	display: block;

	&:hover {
	  color: $primary-color;
	}
  }

  p {
	margin-bottom: 30px;
  }

  &.thumb-small {

  }
}

.teammember-item--author-in-round {
  .teammembers-thumb {
	width: 200px;
	height: 200px;

	img {
	  position: relative;
	  bottom: auto;
	  left: auto;
	  transform: none;
	  border-radius: 100%;
	  box-shadow: 10px 0 30px 0 rgba(18,25,33, 0.15);
	}

	.btn {
	  position: absolute;
	  bottom: 10px;
	  left: 10px;
	}
  }
}

.teammember-list {
  & > li {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid $border-color;

	&:last-child {
	  margin-bottom: 0;
	  padding-bottom: 0;
	  border-bottom: none;
	}
  }
}

.thumb--big {
  .teammembers-thumb {
	width: 260px;
	height: 260px;
  }
}

.teammember-item--author-round-top {
  text-align: center;

  .teammembers-thumb {
	overflow: hidden;
	float: none;
	margin-right: 0;
	margin: 0 auto 30px;

	img {
	  position: relative;
	  bottom: auto;
	  left: auto;
	  transform: none;
	}
  }

  .teammember-content {
	padding-top: 0;
  }
}

/*================= Responsive Mode ============*/


@media (max-width: 1200px) {
  .crumina-teammembers-item .teammembers-thumb {
	float: none;
	margin-right: 0;
  }

  .teammember-item--author-in-round .teammembers-thumb {
	float: left;
	margin-right: 20px;
  }

  .teammember-item--author-round-top .teammembers-thumb {
	margin: 0 auto 30px;
  }
}


@media (max-width: 980px) {
  .teammember-item--author-in-round .teammembers-thumb {
	width: 100px;
	height: 100px;
	float: none;
	margin-right: 0;
	margin-bottom: 0;
  }

  .teammember-item--author-in-round .teammembers-thumb .btn {
	bottom: 0;
	left: 0;
  }
}

@media (max-width: 800px) {
  .thumb--big .teammembers-thumb {
	width: 100px;
	height: 100px;
  }

  .crumina-teammembers-item .teammembers-item-prof {
	font-size: 12px;
  }
}
