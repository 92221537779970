/*------------- #ACCORDIONS --------------*/

.crumina-accordion {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accordion-panel {
  position: relative;
  background-color: $blue-lighteen;
  border-radius: 5px;
  margin-bottom: 20px;
}

.accordion-heading {
  display: table;
  padding: 11px 25px;
  font-size: 18px;
  transition: all .3s ease;
  cursor: pointer;
  outline: none;
  width: 100%;
  background-color: $white-color;
  border: 2px solid $primary-color;
  border-bottom: 0;
  box-shadow: 5px 0 20px rgba(0, 131, 255, .3);

  span {
	display: table-cell;
	vertical-align: middle;
  }

  .title {
	font-weight: 700;
	color: $primary-color;
	text-align: left;
  }

  .icons {
	padding-right: 25px;
	transition: all .3s ease;
	opacity: 1;
	position: relative;
	top: -2px;

	.utouch-icon {
	  height: 10px;
	  width: 10px;
	  fill: #839bbd;
	}

	.active {
	  opacity: 0;
	  position: absolute;
	  left: 0;
	  transition: all .3s ease;
	  top: 11px;
	}
  }

  i, .utouch-icon {
	opacity: 0;

	&.active {
	  opacity: 1;
	  fill: $primary-color;
	}
  }

  &.collapsed {
	background-color: transparent;
	border-color: transparent;
	box-shadow: none;

	.title {
	  font-weight: 700;
	  color: $heading-color;
	}

	i, .utouch-icon {
	  opacity: 1;

	  &.active {
		opacity: 0;
		fill: $primary-color;
	  }
	}
  }
}

.panel-collapse.collapsing,
.panel-collapse.show {
  background: $white-color;
  border: 2px solid $primary-color;
  border-top: 0;
  box-shadow: 5px 15px 20px rgba(0, 131, 255, 0.3);
}

.crumina-accordion .panel-info {
  line-height: 1.3;
  padding: 10px 20px 30px 60px;
  transition: all .3s ease;

  > * {
	float: none;
  }
}


/*=====================================================
                Transition
=======================================================*/

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
	transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
	transition: none;
  }
}

/*================= Responsive Mode ============*/

@media (max-width: 768px) {
  .accordion-heading .title {
	line-height: 1;
	font-size: 13px;
  }

  .crumina-accordion .panel-info {
	font-size: 13px;
  }
}