/*------------- #EVENTS --------------*/


.event-item {

  .img-author {
	width: 42px;
	height: 42px;
	border-radius: 100%;
	margin-right: 18px;
	float: left;
	overflow: hidden;
  }

  .author-name {
	color: $primary-color;
	font-size: 14px;
	margin-bottom: 0;
  }

  .event-title {
	display: block;
	margin-bottom: 15px;
  }

  .author-company {
	font-size: 14px;
	color: $icon-color;
  }
}