/*------------- #CONFERENCE DETAILS --------------*/



.conference-details {
  margin-bottom: 80px;

  .tab-content {
	margin-bottom: 80px;
  }
}

.conference-details-control {
  margin-bottom: 80px;
}

.tabs-with-line {
  .tab-control {
	width: 20%;

	a {
	  font-size: 18px;
	  color: $icon-color;
	  text-transform: none;

	  &:after {
		background-color: $icon-color;
		opacity: 1;
		width: 40%;
		height: 4px;
	  }
	}

	&.active a::after {
	  width: 100%;
	  background: $primary-color;
	}

	& + .tab-control {
	  margin-left: 40px;
	}

  }
}

.schedule-item {
  padding: 60px 40px;
  border-radius: 10px;
  box-shadow: 10px 0 50px 0 rgba(18,25,33, 0.1);
  margin-bottom: 40px	;

  .title {
	font-size: 30px;
	padding-bottom: 20px;
	border-bottom: 1px solid $border-color;

	span {
	  font-size: 18px;
	  font-weight: 400;
	}
  }
}

.list-events {
  li {
	& + li {
	  margin-top: 20px;
	}
  }

  a {
	color: $primary-color;
	font-size: 14px;
  }

  .event-title {
	font-size: 16px;
  }

  &.col-2 {
	li {
	  width: 49%;
	  display: inline-block;

	  & + li {
		padding-left: 25px;
		margin-top: 0;
	  }
	}
  }
}


/*================= Responsive Mode ============*/


@media (max-width: 980px) {
  .tabs-with-line .tab-control + .tab-control {
	margin-left: 10px;
  }

  .list-events.col-2 li {
	width: 100%;
  }

  .schedule-item {
	padding: 30px 15px;
  }
}

@media (max-width: 560px) {
  .tabs-with-line .tab-control a {
	font-size: 13px;
  }
}

@media (max-width: 460px) {
  .tabs-with-line .tab-control {
	display: block;
	margin-bottom: 15px;
  }

  .tabs-with-line .tab-control + .tab-control {
	margin-left: 0;
  }
}