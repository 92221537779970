/*------------- #CLIENTS --------------*/



.crumina-clients {
  padding: 95px 0;
  background-image: url("../img/clouds12.png");
}

.clients-item {
  position: relative;
  min-height: 50px;
  display: flex;

  &:hover {
    img {
      opacity: 0;
    }

    .hover {
      opacity: 1;
    }
  }

  img {
    position: relative;
    transition: all .3s linear;
    transform-style: preserve-3d;

    &.hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 5;
    }
  }

  .clients-images {
    position: relative;
    display: block;
    margin: auto;
  }
}



/*================= Responsive Mode ============*/


@media (max-width: 1200px) {
  .crumina-clients {
    padding: 40px 0;
  }

  .clients-item {
    margin-bottom: 20px;
  }
}