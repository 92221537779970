/*------------- #TESTIMONIAL --------------*/



.testimonials-wrap {

  .crumina-testimonial-item {
	margin-bottom: 60px;
  }
}

.crumina-testimonial-item {
  position: relative;

  .rait-stars {
	position: relative;
	z-index: 2;
  }

  .author-name {
	display: block;
	margin: 0;
	color: $primary-color;
  }

  .author-company {
	color: #63768d;
	display: block;
  }

  .testimonial-text {
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
  }
}

.testimonial-img-author {
  border-radius: 100%;
  overflow: hidden;
  text-align: center;
}

.testimonial-item-author-top {
  text-align: center;
  padding-top: 20px;

  .author-info {
	text-align: center;
  }

  .testimonial-text {
	font-size: 20px;
	margin-bottom: 70px;
	line-height: 1.3;
  }

  .testimonial-img-author {
	width: 60px;
	height: 60px;
	line-height: 60px;
	margin: 0 auto 40px;
	box-shadow: 10px 0 30px 0 rgba(81,116,141, .4);
  }

  .author-name {
	margin-bottom: 10px;
  }

}

.testimonial-item-arrow {
  padding: 40px;
  background-color: $white-color;
  border-radius: 10px;
  position: relative;
  box-shadow: 10px 0 10px 0 rgba(18,25,33, .1);
  margin-top: 15px;

  .testimonial-text {
	margin-bottom: 20px;
  }

  .author-info-wrap {
	margin-bottom: 10px;

	& > * {
	  display: inline-block;
	  vertical-align: middle;
	}
  }

  .testimonial-img-author {
	margin-right: 20px;
	margin-bottom: 15px;
	width: 60px;
	height: 60px;
	line-height: 60px;
	box-shadow: 10px 0 30px 0 rgba(81, 116, 141, 0.4);
  }
}

.testimonial-item-author-left {
  .testimonial-img-author {
	width: 160px;
	height: 160px;
	line-height: 310px;
	float: left;
	background-color: #edf6fc;
	margin-right: 40px;
  }

  .author-name {
	margin-bottom: 25px;
  }

  .testimonial-text {
	font-weight: 400;
	margin-bottom: 25px;
  }

  .author-info-wrap {
	overflow: hidden;
  }
}

.testimonial-item-quote-right {
  padding: 0 40px 40px;
  background-color: $white-color;
  border-radius: 10px;
  box-shadow: 10px 0 50px 0 rgba(18,25,33, .1);
  overflow: visible;
  margin-top: 80px;

  .testimonial-img-author {
	text-align: left;
	margin-bottom: -30px;
	top: -60px;
	position: relative;
	overflow: visible;

	img {
	  border-radius: 100%;
	  box-shadow: 10px 0 30px 0 rgba(18,25,33, .15);
	}

	.share-product, .social__item.main {
	  top: auto;
	  bottom: 0;
	  right: auto;
	  left: 10px;
	}
  }

  .author-name {
	font-size: 22px;
	margin-bottom: 10px;
	color: inherit;

	&:hover {
	  color: $primary-color;
	}
  }

  .author-company {
	font-size: 14px;
	font-weight: $h-font-weight;
	margin-bottom: 20px;
  }

  .testimonial-text {
	font-weight: 400;
	margin-bottom: 35px;
  }

  .quote {
	position: absolute;
	bottom: 20px;
	right: 40px;

	.utouch-icon {
	  width: 60px;
	  height: 60px;
	  fill: #e6eff9;
	}
  }

}

.testimonial-item-quote-top {
  text-align: center;

  .author-info {
	text-align: center;
  }

  .testimonial-text {
	font-size: 28px;
	margin-bottom: 35px;
  }

  .author-name {
	margin-bottom: 5px;
  }

  .quote {
	.utouch-icon {
	  width: 100px;
	  height: 100px;
	  fill: #e6eff9;
	}
  }
}


.rait-stars {
  .utouch-icon {
	height: 13px;
	width: 13px;
	fill: $yellow;
  }

  li {
	display: inline-block;

	& + li {
	  margin-left: 2px;
	}
  }
}



/*================= Responsive Mode ============*/

@media (max-width: 990px) {
  .testimonial-item-author-left .testimonial-img-author {
	float: none;
	margin-right: 0;
	margin-bottom: 20px;
  }
}

@media (max-width: 980px) {
  .testimonial-item-arrow {
	padding: 30px;
  }
}

@media (max-width: 460px) {
  .testimonial-item-quote-right {
	padding: 0 10px 40px;
  }

  .testimonial-item-author-top .testimonial-text {
	margin-bottom: 20px;
  }

  .testimonial-item-quote-top .testimonial-text {
	font-size: 20px;
  }
}
