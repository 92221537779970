/*------------- #ZOOM IMAGE --------------*/


.crumina-zoom-image {
  position: relative;
  display: inline-block;

  img {
	display: inline-block;
  }

  &:hover {
	.zoom-round-img {
	  -webkit-transition: all 2.5s cubic-bezier(0.500, -0.010, 0.000, 1.005);
	  -moz-transition: all 2.5s cubic-bezier(0.500, -0.010, 0.000, 1.005);
	  -o-transition: all 2.5s cubic-bezier(0.500, -0.010, 0.000, 1.005);
	  transition: all 2.5s cubic-bezier(0.500, -0.010, 0.000, 1.005);
	  -webkit-transform: perspective(50em) translateZ(0) translateY(50%) rotateY(0deg) rotateX(3deg) scale(1);
	  -moz-transform: perspective(50em) translateZ(0) translateY(50%) rotateY(0deg) rotateX(3deg) scale(1);
	  -ms-transform: perspective(50em) translateZ(0) translateY(50%) rotateY(0deg) rotateX(3deg) scale(1);
	  -o-transform: perspective(50em) translateZ(0) translateY(50%) rotateY(0deg) rotateX(3deg) scale(1);
	  transform: perspective(50em) translateZ(0) translateY(50%) rotateY(0deg) rotateX(3deg) scale(1);
	}
  }

}

.zoom-round-img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 50%) scale(0.4) rotate(90deg);
  max-width: 100%;
  border-radius: 100%;
  border: 14px solid rgba(255,255,255,.1);
  box-shadow: 30px 0 30px rgba(0,0,0,.3);
  overflow: hidden;
  transition: all .3s ease;
}