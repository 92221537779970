/*------------- #SORTING --------------*/


.cat-list-bg-style {
  margin: 50px 0;
  padding: 0;
  list-style: none;

  .cat-list__item {
	display: inline-block;
	margin: 0 5px;
	border-radius: 30px;
	overflow: hidden;

	a {
	  font-size: 14px;
	  text-transform: uppercase;
	  transition: all .3s ease;
	  padding: 6px 20px;
	  display: block;
	  color: $icon-color;
	  font-weight: $h-font-weight;

	  &:hover {
		color: $white-color;
		background-color: $primary-color;
		box-shadow: 10px 0 30px rgba(0, 131, 255, 0.3);
	  }
	}

	&.active {
	  background-color: $primary-color;
	  box-shadow: 10px 0 30px rgba(0, 131, 255, 0.3);

	  a {
		color: $white-color;
		box-shadow: 10px 0 30px rgba(0, 131, 255, 0.3);
	  }
	}
  }
}

.screenshots-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: all .3s ease;
  box-shadow: 10px 0 50px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  float: left;
  width: 100%;

  img {
	width: 100%;
	display: block;
  }

  .overlay-standard {
	opacity: 0;
  }

  .expand {
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }

  &:hover {
	transform: scale(.9);
	box-shadow: 10px 0 50px rgba(0, 131, 255, 0.5);

	.overlay-standard {
	  opacity: 1;
	}

	.expand {
	  opacity: 1;
	}
  }

  &.style-2 {
	border-radius: 0;
	margin-bottom: 0;

	&:hover {
	  transform: none;
	}
  }

}

.expand {
  text-align: center;
  color: $white-color;
  font-size: 16px;
  font-weight: $h-font-weight;

  .utouch-icon {
	width: 35px;
	height: 35px;
	fill: $white-color;
	margin: 0 auto 10px;
	display: block;
  }
}