/*------------- #BLOCKS --------------*/


@import "theme-styles/variables";
@import "blocks/heading";
@import "blocks/nice-select";
@import "blocks/forms";
@import "blocks/alerts";
@import "blocks/buttons";
@import "blocks/sliders";
@import "blocks/info-box";
@import "blocks/testimonials";
@import "blocks/pagination";
@import "blocks/our-video";
@import "blocks/clients";
@import "blocks/counters";
@import "blocks/pricing-tables";
@import "blocks/subscribe-form";
@import "blocks/module-img";
@import "blocks/socials";
@import "blocks/teammembers";
@import "blocks/product-case";
@import "blocks/zoom-image";
@import "blocks/accordion";
@import "blocks/blogs";
@import "blocks/navigation";
@import "blocks/events";
@import "blocks/pagination-arrow";
@import "blocks/comments";
@import "blocks/screenshots";
@import "blocks/skills";
@import "blocks/conference-details";
@import "blocks/course-details";
@import "blocks/course-features";
@import "blocks/reviews";
@import "blocks/raiting-details";
@import "blocks/curriculum-list";
@import "blocks/curriculum-event";
@import "blocks/tripple-image";
@import "blocks/smartphone-video";
@import "blocks/sorting";
@import "blocks/choose";
@import "blocks/dotted-map";
@import "blocks/send-message-popup";
@import "blocks/counting-down";
@import "blocks/skew-rows";
@import "blocks/crumina-double-image";
@import "blocks/switcher";
@import "blocks/jquery.typeahead";
@import "blocks/popup-search";
@import "blocks/range-slider";
@import "blocks/leaflet";
