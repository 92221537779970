/*------------- #BLOGS --------------*/


.post-thumb {
  position: relative;
  border-radius: 10px 10px 0 0;
  overflow: hidden;

  img {
	border-radius: 10px 10px 0 0;
	display: block;
	width: 100%;
  }

  .video-control img {
	border-radius: 20px;
  }
}

.post {
  border-radius: 10px;
  box-shadow: 10px 0 50px rgba(18,25,33,.1);
  transition: all .3s ease;
  margin-bottom: 60px;
  position: relative;

  p {
	margin-bottom: 30px;
  }

  .overlay-standard {
	opacity: 0;
  }

  .link-image {
	position: absolute;
	top: 50%;
	left: 40%;
	transform: translate(0, -50%) scale(0);
	opacity: 0;
	z-index: 50;
	transition: all .6s ease;

	.utouch-icon {
	  height: 40px;
	  width: 40px;
	  fill: $white-color;
	}
  }

  .link-post {
	position: absolute;
	top: 50%;
	right: 40%;
	transform: translate(0, -50%) scale(0);
	opacity: 0;
	z-index: 50;
	transition: all .6s ease;

	.utouch-icon {
	  height: 40px;
	  width: 40px;
	  fill: $white-color;
	}
  }

  &:hover {
	box-shadow: 10px 0 50px rgba(0,131,255,.2);

	.post__title {
	  color: $primary-color;
	}

	.overlay-standard {
	  opacity: 1;
	}

	.link-post, .link-image {
	  opacity: 1;
	  transform: translate(0, -50%) scale(.9);

	  &:hover {
		transform: translate(0, -50%) scale(1);
	  }
	}
  }

  &.sticky:before {
	content: '';
	background: url("../svg/icon252.svg") 50% 50% no-repeat;
	background-size: 60%;
	display: block;
	text-align: center;
	position: absolute;
	z-index: 5;
	top: 60px;
	right: 100%;
	height: 45px;
	width: 35px;
	background-color: $red;
	padding: 10px;
	border-radius: 10px 0 0 10px;
  }

  &.quote {

	.post-thumb {
	  padding: 100px;

	  @media (max-width: 1024px) {
		padding: 30px;
	  }
	}

	.testimonial-content {
	  position: relative;
	}

	.testimonial-img-author {
	  float: left;
	  margin-right: 30px;
	  width: 60px;
	  height: 60px;
	}

	.author-company {
	  font-weight: $h-font-weight;
	}

	.author-name {
	  margin: 0;
	}

	.text {
	  font-size: 36px;
	  color: $white-color;
	  line-height: 1.2;
	  font-weight: $h-font-weight;

	  @media (max-width: 570px) {
		font-size: 18px;
	  }
	}

	.quote {
	  position: absolute;
	  right: 0;
	  bottom: 0;
	  width: 100px;

	  @media (max-width: 360px) {
		display: none;
	  }
	}
  }

  &.video {
	.overlay-standard {
	  opacity: 1;
	}

	.video-control {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  z-index: 10;
	}

  }

  &.link {

	.post-thumb {
	  padding: 95px 100px;

	  @media (max-width: 1024px) {
		padding: 80px;
	  }

	  @media (max-width: 570px) {
		padding: 30px;
	  }

	  .site-link {
		color: $yellow;
		display: block;
	  }
	}

	.thumb-content-title {
	  color: $white-color;
	  margin-bottom: 30px;
	  display: block;
	  font-size: 36px;
	}

	.thumb-content {
	  position: relative;
	  z-index: 50;

	  .post-link {
		height: 68px;
		width: 68px;
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
	  }
	}
  }
}

.post__content {
  padding: 60px;
  position: relative;
}

.post__content-info {
  overflow: hidden;
}

.post__date {
  float: left;
  margin-right: 60px;
  font-weight: $h-font-weight;
  font-size: 14px;

  & time > * {
	display: block;
	line-height: 1;
  }

  .number {
	font-size: 50px;
	margin-bottom: 10px;
	color: $icon-color;
  }

  .month {
	margin-bottom: 7px;
	color: $secondary-color;
  }

  .day {
	font-weight: 400;
	color: #7b9abf;
  }
}

.post__title {
  margin-bottom: 15px;
  display: block;
  margin-top: 0;
  line-height: 1.1em;
}

.post-additional-info {
  color: $icon-color;

  & > * {
	display: inline-block;
  }

  & > * + * {
	margin-left: 30px;
  }

  a {
	color: $heading-color;
	font-weight: $h-font-weight;

	&:hover {
	  color: $primary-color;
	}

	span {
	  color: $icon-color;
	}
  }

  .btn-next {
	float: right;
	opacity: 1;
	top: -5px;
	margin-right: 5px;

	&:hover {
	  margin-right: 0;

	  .icon-hover {
		opacity: 1;
	  }
	}
  }
}

.share-product {
  position: absolute;
  right: 60px;
  top: -20px;
  height: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  visibility: hidden;
  padding-right: 50px;
  z-index: 999;
  cursor: pointer;

  .socials {

	margin: 0;

	li {
	  margin-right: 5px;

	  &:last-child {
		margin-right: 0;
	  }
	}
  }

  &.open {
	visibility: visible;

	.social__item.twitter{
	  -webkit-transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  -webkit-transition-duration:0.9s;
	  transition-duration:0.9s;
	}

	.social__item.facebook{
	  -webkit-transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  -webkit-transition-duration:0.8s;
	  transition-duration:0.8s;
	}

	 .social__item.facebook{
	  -webkit-transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  -webkit-transition-duration:0.8s;
	  transition-duration:0.8s;
	}

	.social__item.googlePlus{
	  -webkit-transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  -webkit-transition-duration:0.7s;
	  transition-duration:0.7s;
	}

	.social__item.pinterest{
	  -webkit-transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  -webkit-transition-duration:0.6s;
	  transition-duration:0.6s;
	}

	.social__item.mail{
	  -webkit-transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
	  -webkit-transition-duration:0.5s;
	  transition-duration:0.5s;
	}
  }
}

.share-product .social__item {
  width: 40px;
  height: 40px;
  border-radius:100%;
  display:inline-block;
  opacity:0;
  text-align: center;
  -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  overflow: hidden;
  position: relative;
  -webkit-transition:0.85s;
  transition:0.85s;

  .utouch-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
}

.share-product .social__item .utouch-icon{
  height:16px;
  width: 16px;
  fill:$white-color;
}

.social__item.main {
  opacity: 1;
  filter: alpha(opacity=100);
  padding: 0;
  z-index: 5;
  letter-spacing: -0.5px;
  position:absolute;
  cursor:pointer;
  right: 60px;
  top: -20px;
  -webkit-transition:0.1s;
  transition:0.1s;
  will-change: max-width, padding-right, opacity, text-indent;
  width: 40px;
  height: 40px;
  line-height: 48px;
  background-color: $white-color;
  border-radius: 100%;
  box-shadow: 0 0 30px rgba(39, 63, 91, 0.2);
  text-align: center;

  .utouch-icon, svg {
	height: 18px;
	width: 18px;
	color: #829dbc;
	fill: #829dbc;
	position: relative;
  }

  &.item--small {
	width: 32px;
	height: 32px;
	line-height: 40px;
  }

}

.share-product .social__item .utouch-icon {
  outline: 26px solid rgba(44,42,42,0);
  outline-offset: -12px;
  -webkit-transition:0.25s;
  transition:0.25s;
}
.share-product .social__item:hover .utouch-icon {
  outline: 26px solid rgba(44,42,42,0.20);
  fill: $white-color;
}

.share-product .social__item.twitter{
  margin-left:0;
  -webkit-transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
  transition-timing-function:cubic-bezier(.41,1.26,.56,.99);
  -webkit-transform:translateX(160px) rotate(582deg);
  -ms-transform:translateX(160px) rotate(582deg);
  transform:translateX(160px) rotate(582deg);
}

.twitter {
  background:#55acee;
}

.share-product .social__item.facebook{
  -webkit-transform:translateX(120px) rotate(436deg);
  -ms-transform:translateX(120px) rotate(436deg);
  transform:translateX(120px) rotate(436deg);
}
.facebook {
  background:#3b5998;
}

.share-product .social__item.googlePlus{
  -webkit-transform:translateX(80px) rotate(290deg);
  -ms-transform:translateX(80px) rotate(290deg);
  transform:translateX(80px) rotate(290deg);
}

.googlePlus {
  background:#dd4b39;
}

.share-product .social__item.pinterest{
  -webkit-transform:translateX(40px) rotate(145deg);
  -ms-transform:translateX(40px) rotate(145deg);
  transform:translateX(40px) rotate(145deg);
}

.pinterest {
  background:#cc2127;
}

.share-product .social__item.mail{
  -webkit-transform:translateX(0) rotate(0deg);
  -ms-transform:translateX(0) rotate(0deg);
  transform:translateX(0) rotate(0deg);
}

.mail {
  background:#996DD1;
}

.share-product:hover .social__item,
.share-product.hover .social__item{
  -webkit-transform:none;
  -ms-transform:none;
  transform:none;
  opacity:1;
}

.rss {
  background-color: #f4b459;
}

.share-product--item-right {
  padding-left: 42px;
  padding-right: 0;
  height: 32px;

  .social__item.main {
	right: auto;
	left: 0;
	width: 32px;
	height: 32px;
  }

  .social__item {
	height: 32px;
	width: 32px;
  }

}


/*------------- #Post-standard-details --------------*/

.post-standard-details {
  margin-bottom: 0;
  border-radius: 10px 10px 0 0;

  .post-additional-info {
	margin-bottom: 25px;
  }

  &:hover {
	.post__title {
	  color: $heading-color;
	}
  }
}

.post-details-shared {
  padding: 55px 0 0;
  border-top: 2px solid $border-color;
}

.block-rounded-shadow {
  .post-details-shared {
	border-top: none;
  }
}

.tags-inline {
  li {
	display: inline-block;
	font-size: 14px;

	& + li {
	  margin-left: 5px;
	}

	&:first-child {
	  font-weight: 700;
	  color: $heading-color;
	}

	a {
	  &:hover {
		color: $primary-color;
	  }
	}
  }
}


.blog-details-author {
  padding: 55px 60px;
  box-shadow: 10px 0 50px rgba(18, 25, 33, 0.1) inset;
  border-radius: 0 0 10px 10px;
  position: relative;

  .author-name {
	color: $heading-color;
	margin-bottom: 15px;
	display: block;
  }

  .img-author {
	float: left;
	margin-right: 40px;

	img {
	  border-radius: 100%;
	  box-shadow: 10px 0 30px rgba(18, 25, 33, 0.15) inset;
	}
  }

  &:before {
	content: '';
	position: absolute;
	display: inline-block;
	top: -15px;
	border-right: 15px solid transparent;
	border-left: 15px solid transparent;
	border-bottom: 15px solid #eff0f0;
	left: 115px;
  }
}



/* =WordPress Core
-------------------------------------------------------------- */

.align-center,
.aligncenter {
  text-align: center;
}

.align-left{
  text-align: left;
}

.align-right {
  text-align: right;
}

img.aligncenter {
  display: block;
  margin: 20px auto;
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 40px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  border: 2px solid $border-color;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  background: #fff;
  text-align: center;

  img {
	margin-bottom: 15px;
  }

  &.wp-caption-text {
	font-size: 14px;
	margin-bottom: 0;
  }
}

.wp-caption-text {
  font-size: 14px;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption p.wp-caption-text {
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}



/*================= Responsive Mode ============*/


@media (max-width: 768px) {
  .post-additional-info {

	& > * {
	  display: block;

	  & + * {
		margin-top: 10px;
		margin-left: 0;
	  }
	}

	.btn-next {
	  margin-right: 0;
	  float: left;
	}

	.icon-hover {
	  opacity: 1;
	}
  }

  .post__content {
	padding: 25px;
  }

  .post__date {
	float: none;
	margin-right: 0;
	margin-bottom: 20px;
  }

  .post.link .post-thumb {
	padding: 30px;
  }

  .blog-details-author .img-author {
	float: none;
	margin-right: 0;
	margin-bottom: 20px;
  }

  .blog-details-author {
	padding: 20px;
  }
}


@media (max-width: 560px) {
  .post .link-image {
	left: 30%;
  }

  .post .link-post {
	right: 30%;
  }

  .social__item.main {
	right: 20px;
  }

  .share-product {
	right: 20px;
  }

  .post.link .thumb-content-title {
	font-size: 20px;
  }

  .post.sticky::before {
	top: 20px;
	height: 30px;
	width: 30px;
  }
}


